@tailwind base;
@tailwind components;
@tailwind utilities;

.particles-container {
    height: calc(100vh - 80px) !important;
}

.gradient-text {
    color: transparent; /* 让文本透明，以便显示背景渐变 */
    font-weight: normal;
    background: linear-gradient(89deg, #3347ff 0%, #ffffff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.bg1 {
    background: url("/static/bg1.png") 0 0/100% 100%;
    background-color: black;
}

.bg1m {
    background: url("/static/bg1_m.png") 0 0/100% 100%;
    background-color: black;
}

.bg2 {
    background: url("/static/bg2.png") 0 0/100% 100%;
}

.bg3 {
    background: url("/static/bg3.png") 0 0/100% 100%;
}

.bg4 {
    background: url("/static/bg4.png") 0 0/100% 100%;
}

.bg5 {
    background: url("/static/bg5.png") 0 0/100% 100%;
    background-color: black;
}

.bg5m {
    background: url("/static/bg5_m.png") 0 0/100% 100%;
    background-color: black;
}

.bg6 {
    background: url("/static/bg6.png") 0 0/100% 100%;
}

.bg7 {
    background: url("/static/bg7.png") 0 0/100% 100%;
}

.bg7m {
    background: url("/static/bg7_m.png") 0 0/100% 100%;
}

.bg8 {
    background: url("/static/bg8.png") 0 0/100% 100%;
    background-color: black;
}

.bg8m {
    background: url("/static/bg8_m.png") 0 0/100% 100%;
    background-color: black;
}

.num-box:hover .line {
    width: 100%;
    background-color: #3347ff;
}

.line {
    transition: all 0.3s;
}

.title-box:hover .title-line {
    width: 100%;
    background-color: #3347ff;
    margin-top: 5px;
}

.title-line {
    width: 0%;
    transition: all 0.3s;
    background-color: transparent;
    height: 4px;
    margin-top: 5px;
}

.navbar {
    z-index: 100;
    @apply transition-all duration-300;

    &.sticky {
        @apply fixed top-0 w-full shadow-lg;
    }
}
